var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TopBar" }, [
    _c(
      "nav",
      {
        staticClass: "navbar is-transparent",
        class: { "is-active": _vm.menuOpen },
        attrs: { role: "navigation", "aria-label": "main navigation" }
      },
      [
        _c("div", {
          staticClass: "navbar-menu-background",
          on: { click: _vm.toogleMenuOpen }
        }),
        _c("div", { staticClass: "navbar-brand" }, [
          _c(
            "a",
            {
              staticClass: "navbar-item",
              class: { pointerEventNone: !_vm.brandClickable },
              on: {
                click: function($event) {
                  _vm.brandClickable ? _vm.$emit("brand-click") : null
                }
              }
            },
            [
              _vm.brandImagePath
                ? _c("img", {
                    attrs: {
                      width: "auto",
                      height: "28",
                      alt: "brand logo",
                      src: _vm.brandImagePath
                    }
                  })
                : _vm._e(),
              _vm.brandName
                ? _c("div", {
                    staticClass: "brandName",
                    domProps: { textContent: _vm._s(_vm.brandName) }
                  })
                : _vm._e()
            ]
          ),
          _vm.hasNavItems
            ? _c(
                "a",
                {
                  staticClass: "navbar-burger",
                  class: { "is-active": _vm.menuOpen },
                  attrs: {
                    role: "button",
                    "aria-label": "menu",
                    "aria-expanded": "false",
                    "data-target": "navbarMenu"
                  },
                  on: { click: _vm.toogleMenuOpen }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }),
                  _c("span", { attrs: { "aria-hidden": "true" } }),
                  _c("span", { attrs: { "aria-hidden": "true" } })
                ]
              )
            : _vm._e()
        ]),
        _vm.navBugerFixed
          ? _c(
              "button",
              {
                staticClass: "nav-buger-fixed button is-white is-small",
                on: {
                  click: function($event) {
                    return _vm.$emit("nav-buger-fixed-clicked")
                  }
                }
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm.hasNavItems
          ? _c(
              "div",
              {
                staticClass: "navbar-menu btnMenu",
                class: { "is-active": _vm.menuOpen },
                attrs: { id: "navbarMenu" }
              },
              [
                _c(
                  "div",
                  { staticClass: "navbar-start" },
                  [_vm._t("start")],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "navbar-end" },
                  _vm._l(_vm.navEndItems, function(item, index) {
                    return _c(
                      "a",
                      {
                        key: "navEndItems_" + index,
                        staticClass: "navbar-item",
                        on: {
                          click: function($event) {
                            return _vm.navbarItemClick(item)
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(item.text) }
                        })
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("img", {
        staticStyle: { opacity: "0.7" },
        attrs: {
          src: require("./../img/icons/bars-solid.svg"),
          alt: "Menu button"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }