// <!-- 22/09/21 -->
<template>
	<div class="TopBar">
		<nav
			class="navbar is-transparent"
			role="navigation"
			aria-label="main navigation"
			:class="{ 'is-active': menuOpen }"
		>
			<div class="navbar-menu-background" @click="toogleMenuOpen"></div>
			<div class="navbar-brand">
				<a
					class="navbar-item"
					:class="{ pointerEventNone: !brandClickable }"
					@click="brandClickable ? $emit('brand-click') : null"
				>
					<img
						v-if="brandImagePath"
						width="auto"
						height="28"
						alt="brand logo"
						:src="brandImagePath"
					/>
					<div class="brandName" v-if="brandName" v-text="brandName"></div>
				</a>

				<a
					v-if="hasNavItems"
					role="button"
					class="navbar-burger"
					:class="{ 'is-active': menuOpen }"
					aria-label="menu"
					aria-expanded="false"
					data-target="navbarMenu"
					@click="toogleMenuOpen"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>
			<button
				v-if="navBugerFixed"
				class="nav-buger-fixed button is-white is-small"
				@click="$emit('nav-buger-fixed-clicked')"
			>
				<span class="icon is-small">
					<img
						src="./../img/icons/bars-solid.svg"
						style="opacity: 0.7"
						alt="Menu button"
					/>
				</span>
			</button>

			<div
				v-if="hasNavItems"
				id="navbarMenu"
				class="navbar-menu btnMenu"
				:class="{ 'is-active': menuOpen }"
			>
				<div class="navbar-start">
					<slot name="start"></slot>
				</div>
				<div class="navbar-end">
					<a
						class="navbar-item"
						v-for="(item, index) in navEndItems"
						:key="`navEndItems_${index}`"
						@click="navbarItemClick(item)"
					>
						<span v-text="item.text"></span>
					</a>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	name: "TopBar",
	props: {
		navEndItems: {
			type: Array,
			default: () => [],
		},
		brandImagePath: {
			type: String,
			default: "",
		},
		brandName: {
			type: String,
			default: "",
		},
		navBugerFixed: {
			type: Boolean,
			default: false,
		},
		brandClickable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuOpen: false,
		}
	},
	computed: {
		hasNavItems() {
			return this.navEndItems.length > 0
		},
	},
	methods: {
		toogleMenuOpen() {
			this.menuOpen = !this.menuOpen
		},
		navbarItemClick(item) {
			if (this.menuOpen) this.toogleMenuOpen()
			if (item.href) window.location.href = item.href
		},
	},
}
</script>  

<style lang="scss" scoped>
.TopBar {
	border-bottom: 1px solid lightgrey;
	padding: 0 20px;
	position: absolute;
	width: 100%;
}
.brandName {
	margin: 0 16px;
}

.navbar-menu-background {
	background-color: rgb(100, 100, 100);
	width: 100vw;
	height: 100vh;
	opacity: 0;
	transition: 0.5ms;
	position: fixed;
	left: 0;
	top: 0;
	transition: 0.5ms;
	pointer-events: none;
}

.divTopBar {
	padding: 10px 0;
}

.nav-buger-fixed {
	position: absolute;
	top: 14px;
	right: 14px;
}

.navbar {
	&.is-transparent {
		background-color: transparent;
		background-image: none;
	}
	&.is-active {
		z-index: 105;
		& .navbar-burger {
			z-index: 110;
			border-radius: 10px;
		}
		& .navbar-menu {
			position: absolute;
			width: 100%;
			border-radius: 16px;
			z-index: 110;
		}
		& .navbar-menu-background {
			opacity: 0.8;
			z-index: 100;
			background: rgb(165, 165, 165);
			pointer-events: inherit;
		}
		& .navbar-burger {
			background-color: rgba(255, 255, 255);
		}
	}
}

.navbar-item[disabled] {
	pointer-events: none;
	opacity: 0.65;
}

.yootoLogo {
	width: 112px;
	margin: auto auto 6px;
	& img {
		border-radius: 12px;
		padding: 4px !important;
	}
}
</style>

